<template>
  <div>
    <h1 style="margin-left: 0%; margin-bottom: 4%">Messagerie</h1>

    <ComposeMail
      ref="composeMail"
      :visible="visible"
      @cancel="closeModal"
      @close="closeModal"
      :is-admin="isAdmin"
      @create="handleCreate"
      @upload_success="handleUploadedFile($event)"
      @remove="handleRemovingFile($event)"
    />
    <a-row type="flex" justify="space-between" class="table_head">
      <a-col :span="7">
        <a-button class="editable-add-btn" type="primary" v-on:click="showModal"
          >Nouveau message</a-button
        >
      </a-col>
      <a-col :span="7" class="margin-bottom">
        <search-component
          v-on:change="searchMessage($event)"
        ></search-component>
      </a-col>
    </a-row>

    <a-tabs
      :defaultActiveKey="activeTabKey"
      :tabPosition="tabPosition"
      :style="{ height: '100vh' }"
      @change="tabsChange"
    >
      <a-tab-pane key="1">
        <span slot="tab">
          <a-icon type="inbox" />
          Boite de réception
        </span>
        <a-spin :spinning="processing_received_messages">
          <EmailsList
            @show_size_change="receivedSizeChange"
            @change="receivedChange"
            :data="received_messages"
            :pagination="received_messages_pagination"
            :isAdmin="isAdmin"
            :isBoiteReception="true"
          />
        </a-spin>
      </a-tab-pane>
      <a-tab-pane key="2">
        <span slot="tab">
          <a-icon type="export" />
          Messages envoyées
        </span>
        <a-spin :spinning="processing_sent_messages">
          <EmailsList
            :data="sent_messages"
            @show_size_change="sentSizeChange"
            @change="sentChange"
            :pagination="sent_messages_pagination"
            :isAdmin="isAdmin"
            :isBoiteReception="false"
          />
        </a-spin>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

import EmailsList from "@/components/common/messagerie/EmailsList";
import ComposeMail from "@/components/common/messagerie/NewMail";
import moment from "moment";
import constants from "@/const/const";
import SearchComponent from "@/components/common/Search";
import _ from "lodash";

const tabPosition = "left";

export default {
  name: "MessagerieIndex",
  components: { ComposeMail, EmailsList, SearchComponent },
  created() {
    this.fetchSentMessages();
    this.fetchReceivedMessages();
    this.$store.cache.dispatch("fetchUsersEmails");
    this.$store.cache.dispatch("fetchOrganisationWilayas");
  },
  data() {
    this.searchMessage = _.debounce(this.searchMessage, 1000);

    return {
      tabPosition,
      visible: false,
      processing_sent_messages: true,
      processing_received_messages: true,
      errors: false,
      isAdmin: true,
      BOITE_RECEPTION: 1,
      SENT_MESSAGES: 2,
      q: "",
    };
  },
  watch: {
    q: function (oldValue, newValue) {
      if (Number(this.activeTabKey) === this.BOITE_RECEPTION) {
        // Boite reception
        this.fetchReceivedMessages();
      } else {
        this.fetchSentMessages();
      }
    },
  },
  computed: {
    ...mapState({
      received_messages: (state) => state.messageries.received_messages,
      received_messages_pagination: (state) =>
        state.messageries.received_messages_pagination,
      sent_messages_pagination: (state) =>
        state.messageries.sent_messages_pagination,
      sent_messages: (state) => state.messageries.sent_messages,
      all_unfiltered_adherents_emails_ids: (state) =>
        state.messageries.users_emails,
    }),
    ...mapGetters({ activeTabKey: "getSelectedTabKey" }),
  },
  methods: {
    getPrams() {
      return {
        q: this.q,
      };
    },
    searchMessage(e) {
      this.q = e;
    },
    receivedSizeChange(size) {
      this.setReceivedMessagesPagination({
        ...this.received_messages_pagination,
        pageSize: size,
      });
      this.fetchReceivedMessages();
    },
    receivedChange(pagination) {
      this.setReceivedMessagesPagination({
        ...this.received_messages_pagination,
        current: pagination,
      });
      this.fetchReceivedMessages();
    },
    sentSizeChange(size) {
      this.setSentMessagesPagination({
        ...this.sent_messages_pagination,
        pageSize: size,
      });
      this.fetchSentMessages();
    },
    sentChange(pagination) {
      this.setSentMessagesPagination({
        ...this.sent_messages_pagination,
        current: pagination,
      });
      this.fetchSentMessages();
    },
    fetchSentMessages() {
      this.processing_sent_messages = true;
      this.getSentMessages(this.getPrams()).finally(
        () => (this.processing_sent_messages = false)
      );
    },
    fetchReceivedMessages() {
      this.processing_received_messages = true;
      this.getReceivedMessages(this.getPrams()).finally(
        () => (this.processing_received_messages = false)
      );
    },
    closeModal() {
      this.clearAttachedFiles();
      this.visible = false;
    },
    showModal() {
      this.visible = true;
    },
    handleUploadedFile($event) {
      this.addAttachementFile($event);
    },
    handleRemovingFile($event) {
      this.destroyFile($event);
    },
    handleCreate() {
      const form = this.$refs.composeMail.form;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        let email = {};
        email.destination = [];
        email.attachements = this.getAttachementFiles();

        email.subject = values.subject;
        email.wilayas = values.wilayas;
        email.message = values.message;
        if (!values.to) {
          this.all_unfiltered_adherents_emails_ids.forEach((email_id) => {
            email.destination.push(`${email_id.id}`);
          });
        } else {
          values.to.forEach((mail) => {
            email.destination.push(this.getIdFromEmail()(mail));
          });
        }
        this.send(email)
          .then(() => {
            this.fetchSentMessages();
            this.$_showSuccessMessage(`${constants.MESSAGE_SENT}`, 5);
            this.visible = false;
            form.resetFields();
          })
          .catch(() => {
            this.$_throwAnError(`${constants.MESSAGE_SENT_FAIL}`, 5);
          });
      });
    },

    tabsChange(val) {
      this.setSelectedTabKey(val);
    },
    getIdFromEmail(email) {
      return this.adherents_emails_ids;
    },
    ...mapActions({
      getSentMessages: "fetchSentMessages",
      getReceivedMessages: "fetchReceivedMessages",
      destroyFile: "destroyAttachedFile",
      send: "sendMessage",
      getUsersEmails: "fetchUsersEmails",
    }),
    ...mapMutations({
      setSelectedTabKey: "setSelectedTabKey",
      addAttachementFile: "addAttachementsFileId",
      setReceivedMessagesPagination: "setReceivedMessagesPagination",
      setSentMessagesPagination: "setSentMessagesPagination",
      clearAttachedFiles: "clearAttachedFilesId",
    }),
    ...mapGetters({
      getAttachementFiles: "getAttachementsIds",
      getIdFromEmail: "getAdherentIdByEmail",
    }),
    moment,
  },
};
</script>

<style scoped>
.margin-bottom {
  margin-bottom: 3%;
}
</style>
